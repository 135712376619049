import React from "react"
import Loadable from "@loadable/component"
// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"
import DoctorDiscussionGuideCTA from "@components/content/DoctorDiscussionGuide/DoctorDiscussionGuide.jsx"
import PatientFeedbackWhy from "@components/content/patientFeedback/page-why/PatientFeedbackWhy"
import { getLocalizedURL } from '@src/helper'

const Cta = Loadable(() => import('@components/content/cta/CTA'))
const GroupIcon = Loadable(() => import('@components/content/icons/Group.jsx'))
const KidneyLargeIcon = Loadable(() => import('@components/content/icons/KidneyLarge.jsx'))
const LowerIcon = Loadable(() => import('@components/content/icons/Lower.jsx'))
const YogaLargeIcon = Loadable(() => import('@components/content/icons/YogaLarge.jsx'))
const Video = Loadable(() => import('@components/content/video/Video.jsx'))

const whyLupkynis = () => (
    <Layout addedClass="page--why-lupkynis">
        <Seo 
        title="¿Por qué LUPKYNIS&reg; (voclosporina)?" 
        description="LUPKYNIS está ayudando a transformar la forma en que se trata la nefritis lúpica. Consulte los resultados clínicos y obtenga información sobre los beneficios de tomar LUPKYNIS. Consulte la Información de prescripción y seguridad completa y el RECUADRO DE ADVERTENCIA." 
        canonicalURL={"https://www.lupkynis.com" + getLocalizedURL('why-lupkynis','es')} />
        <Hero addedClass="bg-green hero--string">
            <div className="col-wrapper">
                <div className="container">
                    <div className="hero__content">
                        <h1 className="heading heading--accent">¿POR QUÉ LUPKYNIS?</h1>
                        <h2>Un tratamiento que lo ayuda a <span>asumir el desafío</span></h2>
                    </div>
                    <div className="hero__image">
                        <Image data={{
                            desktopSrc: 'why-lupkynis/hero-es.png', 
                            mobileSrc: 'why-lupkynis/hero--mobile-es.png', 
                            altText:"Malisha, quien comenzó el tratamiento en 2021"
                        }} />
                    </div>
                </div>
            </div>
        </Hero>

        <section id="ClinicalTrials" className="bg-purple">
            <div className="container container--narrow">
                <div className="text-center">
                    <h2>LUPKYNIS está ayudando a transformar la forma en que se trata la nefritis lúpica</h2>
                </div>
                <div>
                    <div className="icon-callout">
                        <div className="icon">
                            <YogaLargeIcon />
                        </div>
                        <div className="copy-block">
                            <h4>Ayuda a controlar la nefritis lúpica</h4>
                            <p>Para las personas que toman LUPKYNIS<sup>a</sup>, la probabilidad de tener su nefritis lúpica bajo control<sup>b</sup> después de un año de tratamiento fue casi 3 veces mayor<sup>c</sup> que para las personas que toman solamente micofenolato de mofetilo (MMF) y esteroides.</p> 
                        </div>
                    </div>
                    <div className="icon-callout">
                        <div className="icon">
                            <LowerIcon />
                        </div>
                        <div className="copy-block">
                            <h4>Se redujo la dosis de esteroides necesaria</h4>
                            <p>En ensayos clínicos, LUPKYNIS ayudó a detener el ataque de nefritis lúpica con dosis bajas de esteroides.</p>
                        </div>
                    </div>
                    <div className="icon-callout">
                        <div className="icon">
                            <KidneyLargeIcon />
                        </div>
                        <div className="copy-block">
                            <h4>Reduce las proteínas en la orina</h4>
                            <p>En ensayos clínicos, LUPKYNIS ayudó a reducir la proteína en la orina (llamada proteinuria, un signo clave de actividad de la enfermedad) aproximadamente 2 veces más rápido<sup>d</sup> que MMF y esteroides solos.</p>
                            <p>Las personas que tomaban LUPKYNIS vieron una reducción del 50 % de la proteinuria, en promedio, después de solo un mes.</p>
                        </div>
                    </div>
                    <div className="icon-callout">
                        <div className="icon">
                            <GroupIcon />
                        </div>
                        <div className="copy-block">
                            <h4>Se estudió en diferentes razas y orígenes étnicos</h4>
                            <p>LUPKYNIS se analizó en estudios clínicos en los que participaron más de 350&nbsp;personas de diferentes razas y orígenes étnicos, incluido el 32,5&nbsp;% que eran hispanos.</p>
                        </div>
                    </div>
                </div>
                <div className="footnote">
                    <p><sup>a</sup>Como parte de un plan de tratamiento que incluía micofenolato de mofetilo y esteroides.</p>
                    <p><sup>b</sup>Se definió el control como la reducción de la proteína en la orina a &le;0.5&nbsp;mg/mg, mientras ayudaba a mantener la función renal.</p>
                    <p><sup>c</sup>El cociente de probabilidades fue de 2.7.</p>
                    <p><sup>d</sup>En promedio después de seis meses de tratamiento en comparación con un año para los pacientes que solo toman MMF y esteroides.</p>
                </div>
                <div className="text-center">
                    <Cta url={getLocalizedURL('taking-lupkynis','es')}>Qué esperar al tomar LUPKYNIS</Cta>
                </div>
            </div>
        </section>
        <section id="WatchVideo" className="col-wrapper bg-white">
            <div className="container">
                <div className="copy-block">
                    <h2>Vea cómo LUPKYNIS ayuda a las personas</h2>
                    <p>Malisha, Suriya y Jennifer están luchando contra la nefritis lúpica — mire sus historias para ver cómo LUPKYNIS los ayuda a hacerlo.</p>
                </div>
                <Video 
                src="/videos/the-common-thread-of-lupus-nephritis-part2.mp4" 
                type="video/mp4" 
                poster="/images/taking-lupkynis/watch-real-people-stories-poster-pt-2.png" 
                title="Vea cómo LUPKYNIS ayuda a las personas" controlsList="nodownload" />
            </div>
        </section>
        <PatientFeedbackWhy />
        <DoctorDiscussionGuideCTA />
    </Layout>
)

export default whyLupkynis